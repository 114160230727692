import { Helmet } from "react-helmet-async";
import { useContext, useEffect, useState } from "react";

import { useParams, useNavigate, useSearchParams } from "react-router-dom";

import { Calendar } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
// @mui
import { Button, Container, Divider, Grid, Select, MenuItem, InputAdornment, TextField, Typography, Alert, Snackbar, Checkbox, FormGroup, FormControlLabel } from "@mui/material";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { GlobalContext } from "src/context/GlobalState";
import headerStyle from "src/styles/headerStyle";
import { addProductButton, addVariantButton } from "src/styles/styles";
import promoStyle from "src/styles/promoStyle";
import { CalendarMonth, ContentCopy } from "@mui/icons-material";
import { PROMO_CHANNEL, PROMO_OFFER_TYPE, PROMO_STATUS, VALIDITY_TYPE } from "src/constants/constants";
import { createOrUpdatePromo, fetchPromoConfigDetails, fetchPromoDataByPromoCodeId } from "src/api/promo";
import { promoCreateOrUpdateValidator } from "src/validators/promo.validation";
import { convertEndDateToEAT, convertEndDateToUTC, convertStartDateToEAT, fDate } from "src/utils/formatTime";
import { parseISO, parse, format, subDays, setHours, setMinutes, setSeconds, subHours, addHours } from "date-fns";
import SystemLoader from "src/components/dialogs/Loader/Loader";
import PromoProduct from "src/components/promos/PromoProduct";
import PromoBrand from "src/components/promos/PromoBrand";
import { statusCodes } from "src/utils/statusCodeUtils";
import { orange } from "@mui/material/colors";

export default function PromoDetails() {
  let { promoId } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const searchQuery = searchParams.get("search") || null;

  const [showCalendarValidFrom, setShowCalendarValidFrom] = useState(false);
  const [showCalendarValidTill, setShowCalendarValidTill] = useState(false);
  const [copied, setCopied] = useState(false);

  const {
    dispatch,
    state: { promoState, alertState }
  } = useContext(GlobalContext);

  const fetchPromoConfig = async () => {
    try {
      const response = await fetchPromoConfigDetails();
      if (response.httpStatusCode === 200) {
        const {
          data: { visibilityType, promoChannel, offerType, promoStatus }
        } = response?.result;
        dispatch({ type: "promoConfig", payload: { ...promoState?.promoConfig, visibilityType, promoChannel, offerType, promoStatus } });
      } else {
        alert(response?.message);
      }
    } catch (error) {
      alert(error?.message);
    }
  };

  const fetchPromoCodeDetails = async () => {
    try {
      dispatch({ type: "loading", payload: true });
      const response = await fetchPromoDataByPromoCodeId(promoId);
      if (response.httpStatusCode === 200) {
        const { promoCodeId, name, description, promoCode, validFrom, validTo, visibilityType, promoChannel, offerType, status, discount, minOrderValue, termAndConditions, productVariantId, quantity, maxOrderValue, validityType, redemptionLimit, promoProduct, brandId, promoBrand, rulesJson } = response.result.data;
        dispatch({
          type: "promoRecord",
          payload: {
            ...promoState?.promoRecord,
            promoCodeId,
            name,
            description,
            promoCode,
            validFrom,
            validTo,
            visibilityType,
            promoChannel,
            offerType,
            status,
            discount,
            minOrderValue,
            termAndConditions,
            productVariantId,
            quantity,
            maxOrderValue,
            validityType,
            redemptionLimit,
            brandId,
            rulesJson: rulesJson ? JSON.parse(rulesJson) : { firstDelivery: false, deliveryFreeWaiveOff: false }
          }
        });
        if (productVariantId && promoChannel === PROMO_CHANNEL.product.id) dispatch({ type: "promoProduct", payload: { ...promoState.promoProduct, productVariantId: promoProduct?.productVariantId ?? null, imgUrl: promoProduct?.productVariantImage[0]?.imageUrl ?? null, productName: `${promoProduct?.brandProduct?.name ?? ""} ${promoProduct?.product?.name ?? ""}`, variation: promoProduct?.variantType?.name ?? null, sokoPrice: promoProduct?.productVariantPrice[0]?.price ?? null } });
        if (brandId && promoChannel === PROMO_CHANNEL.brand.id) dispatch({ type: "brandProduct", payload: { ...promoState.brandProduct, brandId: promoBrand?.brandId ?? null, imgUrl: promoBrand?.imageUrl ?? null, brandName: promoBrand?.name ?? null } });
        dispatch({ type: "loading", payload: false });
      } else if (response.httpStatusCode === statusCodes.unAuthorizedAccess) {
        dispatch({ type: "loading", payload: false });
        dispatch({ type: "alertDetail", payload: { ...alertState?.alertDetail, title: "UnAuthorized access", heading: response?.message, body: response?.displayMessage, type: "warning" } });
        dispatch({ type: "showMessage", payload: true });
      } else {
        dispatch({ type: "loading", payload: false });
        alert(response?.message ?? "Unable to fetch Promo code details");
      }
    } catch (error) {
      dispatch({ type: "loading", payload: false });
      alert(error?.message);
    }
  };

  useEffect(() => {
    if (Object.keys(promoState.promoConfig).length === 0) {
      fetchPromoConfig();
    }

    //fetch PromoConfig
    if (promoId === "add") {
      dispatch({ type: "addPromoCode", payload: true });
    } else {
      fetchPromoCodeDetails();
      dispatch({ type: "updatePromoCode", payload: true });
    }
  }, []);

  const handleDateSelectValidFrom = (date) => {
    dispatch({ type: "promoRecord", payload: { ...promoState?.promoRecord, validFrom: format(date, "yyyy-MM-dd HH:mm:ss") } });
    dispatch({ type: "validFromDisplay", payload: date });
    setShowCalendarValidFrom(false);
    let adjustedDate = new Date(format(date, "yyyy-MM-dd HH:mm:ss"));
    adjustedDate = subHours(adjustedDate, 3);
    adjustedDate = setMinutes(adjustedDate, 0);
    adjustedDate = setSeconds(adjustedDate, 0);
    dispatch({ type: "validFromUTC", payload: format(adjustedDate, "yyyy-MM-dd HH:mm:ss") });
  };

  const handleDateSelectValidTill = (date) => {
    dispatch({ type: "promoRecord", payload: { ...promoState?.promoRecord, validTo: format(date, "yyyy-MM-dd HH:mm:ss") } });
    dispatch({ type: "validToDisplay", payload: date });
    setShowCalendarValidTill(false);
    let adjustedDate = new Date(format(date, "yyyy-MM-dd HH:mm:ss"));
    adjustedDate = setHours(adjustedDate, 20);
    adjustedDate = setMinutes(adjustedDate, 59);
    adjustedDate = setSeconds(adjustedDate, 59);
    dispatch({ type: "validToUTC", payload: format(adjustedDate, "yyyy-MM-dd HH:mm:ss") });
  };

  const createOrUpdatePromoCode = async () => {
    try {
      // Handle validation
      const validateResp = promoCreateOrUpdateValidator({ promoObj: promoState?.promoRecord, addPromoCode: promoState?.addPromoCode });
      if (validateResp?.error) {
        alert(validateResp?.error ?? "An error occured");
        return;
      }

      dispatch({ type: "loading", payload: true });
      if (validateResp.validFrom && promoState.validFromUTC) validateResp.validFrom = promoState.validFromUTC;
      if (validateResp.validTo && promoState.validFromUTC) validateResp.validTo = promoState.validToUTC;
      validateResp.rulesJson = JSON.stringify(promoState?.promoRecord?.rulesJson);
      // Create/update
      const response = await createOrUpdatePromo(validateResp);
      dispatch({ type: "loading", payload: false });
      if (response.httpStatusCode === 200) {
        alert(response?.message ?? "Promo code created successfully");
        dispatch({ type: "promoClear" });
        navigate(`/dashboard/promos${searchQuery ? `?search=${searchQuery}` : ""}`, { replace: true });
      } else if (response.httpStatusCode === statusCodes.unAuthorizedAccess) {
        dispatch({ type: "loading", payload: false });
        dispatch({ type: "alertDetail", payload: { ...alertState?.alertDetail, title: "UnAuthorized access", heading: response?.message, body: response?.displayMessage, type: "warning" } });
        dispatch({ type: "showMessage", payload: true });
      } else {
        dispatch({ type: "loading", payload: false });
        alert(response?.message ?? "Error occured during create or update of promo code");
      }
    } catch (error) {
      alert(error?.message);
    }
  };

  return (
    <>
      <SystemLoader />
      <Helmet>
        <title> PromoDetails </title>
      </Helmet>
      <Container maxWidth="xxl" sx={{ ml: 0, mt: 0, pb: 0 }}>
        <Grid container spacing={1} sx={{ mt: 0 }} style={headerStyle.headerContainer}>
          <Grid item xs={4}>
            <Typography variant="h4">Promo Codes</Typography>
          </Grid>
        </Grid>
        <Divider />
        <Grid sx={{ py: 2, display: "flex", justifyContent: "space-between" }}>
          <Grid item xs={6} style={{ display: "flex", alignItems: "center" }}>
            <Button
              sx={{ mr: 1 }}
              onClick={() => {
                dispatch({ type: "promoClear" });
                navigate(`/dashboard/promos${searchQuery ? `?search=${searchQuery}` : ""}`, { replace: true });
              }}
            >
              <img alt="add" src="/assets/icons/button/back.svg" />
            </Button>
            <Typography variant="h7">{promoState.addPromoCode ? "New Promo" : "Update Promo"}</Typography>
          </Grid>
          <Grid gap={2} style={{ ...headerStyle.subHeaderContainer }}>
            <Grid item xs={4}>
              <Button
                onClick={() => {
                  dispatch({ type: "promoClear" });
                  navigate(`/dashboard/promos${searchQuery ? `?search=${searchQuery}` : ""}`, { replace: true });
                }}
                variant="outlined"
                style={addVariantButton}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button onClick={(e) => createOrUpdatePromoCode(e)} style={{ ...addProductButton }}>
                {promoState?.addPromoCode ? "Create" : "Update"}{" "}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={9} spacing={2}>
            {/* Promo title and description */}
            <Grid xs={9} sx={{ marginBottom: "1em" }}>
              <Typography style={promoStyle.promoTitle}>Promo code</Typography>
              <TextField
                sx={{ marginTop: 0 }}
                fullWidth
                aria-label="Promo Code"
                margin="normal"
                value={promoState?.promoRecord?.name ?? ""}
                variant="standard"
                onChange={(e) => {
                  dispatch({ type: "promoRecord", payload: { ...promoState?.promoRecord, name: e.target.value, promoCode: `${e.target.value && e.target.value.toUpperCase().replace(/\s/g, "")}` } });
                }}
              />
            </Grid>
            <Grid xs={9} sx={{ marginBottom: "1em" }}>
              <Typography style={promoStyle.promoTitle}>Description</Typography>
              <TextField
                sx={{ marginTop: 0 }}
                fullWidth
                aria-label="Promo Description"
                margin="normal"
                multiline
                value={promoState?.promoRecord?.description ?? ""}
                variant="standard"
                onChange={(e) => {
                  dispatch({ type: "promoRecord", payload: { ...promoState?.promoRecord, description: e.target.value } });
                }}
              />
            </Grid>
            {/* Promo channel and min order value*/}
            <Grid xs={9} container gap={2} style={{ ...promoStyle.promoSummarySubContainer, marginBottom: "1em" }}>
              <Grid xs={4} sx={{ mr: 3 }}>
                <Typography style={promoStyle.promoTitle}>Promo Channel</Typography>
                <Grid gap={0.5}>
                  <Select
                    value={promoState?.promoRecord?.promoChannel ?? null}
                    defaultValue={PROMO_CHANNEL.sokoCart.id}
                    onChange={(e) => {
                      dispatch({ type: "promoRecord", payload: { ...promoState?.promoRecord, promoChannel: Number(e.target.value) } });
                    }}
                    fullWidth
                    variant="standard"
                  >
                    <MenuItem value={PROMO_CHANNEL.sokoCart.id}>{PROMO_CHANNEL.sokoCart.name}</MenuItem>
                    <MenuItem value={PROMO_CHANNEL.product.id}>{PROMO_CHANNEL.product.name}</MenuItem>
                    <MenuItem value={PROMO_CHANNEL.brand.id}>{PROMO_CHANNEL.brand.name}</MenuItem>
                  </Select>
                </Grid>
              </Grid>
              {/* Promo product search */}
              {promoState.promoRecord.promoChannel === PROMO_CHANNEL.product.id ? <PromoProduct /> : promoState.promoRecord.promoChannel === PROMO_CHANNEL.brand.id ? <PromoBrand /> : null}
            </Grid>

            <Divider sx={{ my: 4, width: "75%" }} />
            {/* Validity*/}
            <Grid xs={{ mt: 2 }}>
              <Typography style={{ fontSize: "16px", fontWeight: "600" }}>Validity</Typography>
            </Grid>
            <Grid xs={9} container style={{ ...promoStyle.promoSummarySubContainer, marginBottom: "1em", marginTop: "8px" }}>
              <Grid xs={3} sx={{ mr: 3 }}>
                <Typography style={promoStyle.promoTitle}>Select Type</Typography>
                <Grid gap={0.5}>
                  <Select
                    value={promoState?.promoRecord?.validityType}
                    defaultValue={promoState?.promoRecord?.validityType}
                    onChange={(e) => {
                      dispatch({ type: "promoRecord", payload: { ...promoState?.promoRecord, validityType: Number(e.target.value), validFrom: Number(e.target.value) === VALIDITY_TYPE.redemptionLimit.id ? null : promoState.promoRecord.validFrom, validTo: Number(e.target.value) === VALIDITY_TYPE.redemptionLimit.id ? null : promoState.promoRecord.validTo, redemptionLimit: Number(e.target.value) === VALIDITY_TYPE.timePeriod.id ? null : promoState.promoRecord.redemptionLimit } });
                    }}
                    fullWidth
                    variant="standard"
                  >
                    <MenuItem value={VALIDITY_TYPE.timePeriod.id}>{VALIDITY_TYPE.timePeriod.name}</MenuItem>
                    <MenuItem value={VALIDITY_TYPE.redemptionLimit.id}>{VALIDITY_TYPE.redemptionLimit.name}</MenuItem>
                  </Select>
                </Grid>
              </Grid>
              {promoState?.promoRecord?.validityType === VALIDITY_TYPE.redemptionLimit.id ? (
                <Grid xs={3} sx={{ mr: 3 }}>
                  <Typography style={promoStyle.promoTitle}>Set Redemption Limit</Typography>
                  <Grid gap={0.5}>
                    <TextField
                      sx={{ marginTop: 0 }}
                      fullWidth
                      aria-label="Redemption Limit"
                      margin="normal"
                      variant="standard"
                      value={promoState?.promoRecord?.redemptionLimit}
                      onChange={(e) => {
                        dispatch({ type: "promoRecord", payload: { ...promoState?.promoRecord, redemptionLimit: Number(e.target.value) } });
                      }}
                    />
                  </Grid>
                </Grid>
              ) : null}
            </Grid>

            {promoState?.promoRecord?.validityType === VALIDITY_TYPE.timePeriod.id ? (
              <Grid xs={9} container style={{ ...promoStyle.promoSummarySubContainer, border: "1px solid #D9D9D9", padding: "16px", borderRadius: "8px", position: "relative" }}>
                <Grid xs={4} sx={{ mr: 10 }}>
                  <Typography style={promoStyle.promoTitle}>From</Typography>
                  <Grid gap={0.5}>
                    <TextField
                      type="text"
                      onFocus={() => setShowCalendarValidFrom(true)}
                      value={promoState?.promoRecord?.validFrom ? fDate(promoState?.promoRecord?.validFrom) : "DD/MM/YYYY"}
                      readOnly
                      variant="standard"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end" style={{ color: "#2C2C2C" }}>
                            <CalendarMonth />
                          </InputAdornment>
                        )
                      }}
                    />
                    {showCalendarValidFrom && <Calendar date={promoState?.validFromDisplay} onChange={handleDateSelectValidFrom} />}
                  </Grid>
                </Grid>
                <Grid xs={4}>
                  <Typography style={promoStyle.promoTitle}>To</Typography>
                  <Grid gap={0.5}>
                    <TextField
                      type="text"
                      onFocus={() => setShowCalendarValidTill(true)}
                      value={promoState?.promoRecord?.validTo ? fDate(promoState?.promoRecord?.validTo) : "DD/MM/YYYY"}
                      readOnly
                      variant="standard"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end" style={{ color: "#2C2C2C" }}>
                            <CalendarMonth />
                          </InputAdornment>
                        )
                      }}
                    />
                    {showCalendarValidTill && <Calendar date={promoState?.validToDisplay} onChange={handleDateSelectValidTill} />}
                  </Grid>
                </Grid>
              </Grid>
            ) : null}

            <Divider sx={{ my: 4, width: "75%" }} />
            {/* Offerings*/}
            <Grid xs={{ mt: 2 }}>
              <Typography style={{ fontSize: "16px", fontWeight: "600" }}>Offerings</Typography>
            </Grid>
            <Grid xs={9} container style={{ ...promoStyle.promoSummarySubContainer, marginBottom: "1em", marginTop: "8px" }}>
              <Grid xs={3} sx={{ mr: 3 }}>
                <Typography style={promoStyle.promoTitle}>Offer Type</Typography>
                <Grid gap={0.5}>
                  <Select
                    value={promoState?.promoRecord?.offerType ?? null}
                    defaultValue={PROMO_OFFER_TYPE.discount.id}
                    onChange={(e) => {
                      dispatch({ type: "promoRecord", payload: { ...promoState?.promoRecord, offerType: Number(e.target.value) } });
                    }}
                    fullWidth
                    disabled
                    variant="standard"
                  >
                    <MenuItem value={PROMO_OFFER_TYPE.discount.id}>{PROMO_OFFER_TYPE.discount.name}</MenuItem>
                    {/* <MenuItem value={PROMO_OFFER_TYPE.product.id}>{PROMO_OFFER_TYPE.product.name}</MenuItem>
                          <MenuItem value={PROMO_OFFER_TYPE.freeDelivery.id}>{PROMO_OFFER_TYPE.freeDelivery.name}</MenuItem> */}
                  </Select>
                </Grid>
              </Grid>
              {promoState?.promoRecord?.offerType === PROMO_OFFER_TYPE.discount.id && (
                <>
                  <Grid xs={3} sx={{ mr: 3 }}>
                    <Typography style={promoStyle.promoTitle}>Discount</Typography>
                    <Grid gap={0.5}>
                      <TextField
                        sx={{ marginTop: 0 }}
                        fullWidth
                        aria-label="Discount"
                        margin="normal"
                        variant="standard"
                        value={promoState?.promoRecord?.discount}
                        onChange={(e) => {
                          dispatch({ type: "promoRecord", payload: { ...promoState?.promoRecord, discount: e.target.value } });
                        }}
                        InputProps={{
                          endAdornment: <InputAdornment position="end">%</InputAdornment>
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid xs={10} sx={{ mr: 3, my: 1, display: "flex", justifyContent: "space-between" }}>
                    <Grid item xs={4}>
                      <Typography style={promoStyle.promoTitle}>Min. {Object.keys(promoState.promoConfig).length > 0 && Object.entries(promoState?.promoConfig?.promoChannel)?.find(([key, value]) => value === promoState?.promoRecord?.promoChannel)[0]} Value</Typography>
                      <TextField
                        sx={{ marginTop: 0, width: "80%" }}
                        aria-label="Min Order Value"
                        margin="normal"
                        value={promoState?.promoRecord?.minOrderValue ?? null}
                        onChange={(e) => {
                          dispatch({ type: "promoRecord", payload: { ...promoState?.promoRecord, minOrderValue: Number(e.target.value) } });
                        }}
                        variant="standard"
                        InputProps={{
                          startAdornment: <InputAdornment position="start">Ksh</InputAdornment>
                        }}
                      />
                    </Grid>
                    <Grid item xs={4} container gap={1.7} direction="column">
                      <Typography style={promoStyle.promoTitle}>(Min. Discount)</Typography>
                      <Typography style={promoStyle.promoTitle}>{`Ksh ${promoState.promoRecord.discount && promoState.promoRecord.minOrderValue ? Math.round((promoState.promoRecord.discount / 100) * promoState.promoRecord.minOrderValue) : ""}`}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography style={promoStyle.promoTitle}>Max. {Object.keys(promoState.promoConfig).length > 0 && Object.entries(promoState?.promoConfig?.promoChannel)?.find(([key, value]) => value === promoState?.promoRecord?.promoChannel)[0]} Value</Typography>
                      <TextField
                        sx={{ marginTop: 0, width: "80%" }}
                        aria-label="Max Order Value"
                        margin="normal"
                        value={promoState?.promoRecord?.maxOrderValue ?? null}
                        onChange={(e) => {
                          dispatch({ type: "promoRecord", payload: { ...promoState?.promoRecord, maxOrderValue: Number(e.target.value) === 0 ? null : Number(e.target.value) } });
                        }}
                        variant="standard"
                        InputProps={{
                          startAdornment: <InputAdornment position="start">Ksh</InputAdornment>
                        }}
                      />
                    </Grid>
                    <Grid item xs={4} container gap={1.7} direction="column">
                      <Typography style={promoStyle.promoTitle}>(Max. Discount)</Typography>
                      <Typography style={promoStyle.promoTitle}>{`Ksh ${promoState.promoRecord.discount && promoState.promoRecord.maxOrderValue ? Math.round((promoState.promoRecord.discount / 100) * promoState.promoRecord.maxOrderValue) : ""}`}</Typography>
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>

            <Grid xs={9}>
              <Alert severity="info">{promoState?.promoRecord?.offerType === PROMO_OFFER_TYPE.discount.id ? `${promoState?.promoRecord?.promoInfo?.discount ?? null}` : promoState?.promoRecord?.offerType === PROMO_OFFER_TYPE.product.id ? `${promoState?.promoRecord?.promoInfo?.freeProduct ?? null}` : promoState?.promoRecord?.offerType === PROMO_OFFER_TYPE.freeDelivery.id ? `${promoState?.promoRecord?.promoInfo?.freeDelivery ?? null}` : `${promoState?.promoRecord?.promoInfo?.discount}`}</Alert>
            </Grid>
            <Grid xs={9} sx={{ my: 2 }}>
              <Divider />
            </Grid>

            {/* Additional preferences*/}
            <Grid xs={9} sx={{ marginBottom: "1em" }}>
              <Typography style={{ fontSize: "16px", fontWeight: "600" }}>Additional Preferences</Typography>
              <FormGroup>
                <FormControlLabel
                  style={promoStyle.promoTitle}
                  control={
                    <Checkbox
                      onChange={(e) => dispatch({ type: "promoRecord", payload: { ...promoState?.promoRecord, rulesJson: { ...promoState?.promoRecord.rulesJson, firstDelivery: e.target.checked } } })}
                      checked={promoState?.promoRecord?.rulesJson?.firstDelivery}
                      sx={{
                        color: orange[800],
                        "&.Mui-checked": {
                          color: orange[600]
                        }
                      }}
                    />
                  }
                  label="Restrict to the first transaction"
                />
                <FormControlLabel
                  style={promoStyle.promoTitle}
                  control={
                    <Checkbox
                      checked={promoState?.promoRecord?.rulesJson?.deliveryFreeWaiveOff}
                      onChange={(e) => dispatch({ type: "promoRecord", payload: { ...promoState?.promoRecord, rulesJson: { ...promoState?.promoRecord.rulesJson, deliveryFreeWaiveOff: e.target.checked } } })}
                      sx={{
                        color: orange[800],
                        "&.Mui-checked": {
                          color: orange[600]
                        }
                      }}
                    />
                  }
                  label="Waive off all delivery fees"
                />
              </FormGroup>
            </Grid>

            {/* Terms and conditions*/}
            {/* <Grid xs={9} sx={{marginBottom: "1em"}}>
              <Grid style={{display: 'flex', justifyContent: 'space-between', marginBottom: '2em', alignItems:'center'}}>
                <Typography variant="h6">Terms & Conditions</Typography>
                <Button onClick={e => { dispatch({ type: "addPoint", payload: true }) }} variant="outlined" style={addVariantButton}>
                  Add Point
                </Button>
              </Grid>
              {promoState?.updatePromoCode &&  
                <Grid sx={{my:1}}>
                  <TextField
                    sx={{marginTop:0}}  
                    fullWidth
                    aria-label='Soko Price'
                    margin="normal"
                    multiline
                    value="Get eggs"
                    variant="standard"
                    />
                  <Button onClick={e => { dispatch({ type: "addProduct", payload: true }) }} style={{...addProductButton}}>
                    Add point
                  </Button>
                </Grid> 
              }
             
              <Grid style={promoStyle.promoSummarySubContainer}>
                {
                  promoState?.promoRecord?.termAndConditions && promoState?.promoRecord?.termAndConditions.map((point, index)=>(
                  <Grid sx={{mb:1}} key={index}>
                    <Typography style ={promoStyle.promoTitle}>Point {index+1}</Typography>
                    <Grid style={{display: 'flex', justifyContent: 'space-between'}}>
                      <Typography style ={promoStyle.promoSubTitle}>{point}</Typography>
                      <DoDisturbOn fontSize='medium' />
                    </Grid>
                  </Grid>))
                }
                
              </Grid>

            </Grid> */}
          </Grid>
          <Grid item xs={3} style={{ ...promoStyle.promoSummaryContainer }}>
            <Grid style={promoStyle.promoSummarySubContainer}>
              <Typography style={promoStyle.promoTitle}>Promo Code</Typography>
              <Grid sx={{ display: "flex", gap: 1 }}>
                <Typography style={promoStyle.promoSubTitle}>{promoState?.promoRecord?.promoCode}</Typography>
                <CopyToClipboard
                  style={{ cursor: "pointer" }}
                  text={promoState?.promoRecord?.promoCode}
                  onCopy={() => {
                    setCopied(true);
                    setTimeout(() => setCopied(false), 5000);
                  }}
                >
                  <ContentCopy fontSize="small" />
                </CopyToClipboard>
                <Snackbar open={copied} autoHideDuration={5000} message="Copied to clipboard" />
              </Grid>
            </Grid>
            <Grid style={{ ...promoStyle.promoSummarySubContainer, display: "flex", justifyContent: "space-between", paddingRight: "1em" }}>
              <Grid item xs={6}>
                <Typography style={promoStyle.promoTitle}>Promo Channel</Typography>
                <Grid gap={0.5} style={{ display: "flex" }}>
                  <Typography style={promoStyle.promoSubTitle2}>{Object.keys(promoState.promoConfig).length > 0 && Object.entries(promoState?.promoConfig?.promoChannel)?.find(([key, value]) => value === promoState?.promoRecord?.promoChannel)[0]}</Typography>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Typography style={promoStyle.promoTitle}>Discount percentage</Typography>
                <Typography style={promoStyle.promoSubTitle2}> {promoState?.promoRecord?.discount} %</Typography>
              </Grid>
            </Grid>
            <Grid style={{ ...promoStyle.promoSummarySubContainer, display: "flex", justifyContent: "space-between", paddingRight: "1em" }}>
              <Grid item xs={6}>
                <Typography style={promoStyle.promoTitle}>Min Discount</Typography>
                <Grid gap={0.5} style={{ display: "flex" }}>
                  <Typography style={promoStyle.promoTitle}>Ksh.</Typography>
                  <Typography style={promoStyle.promoSubTitle2}>{promoState.promoRecord.discount && promoState.promoRecord.minOrderValue ? Math.round((promoState.promoRecord.discount / 100) * promoState.promoRecord.minOrderValue) : ""}</Typography>
                </Grid>
              </Grid>
              <Grid item xs={6} style={{}}>
                <Typography style={promoStyle.promoTitle}>Max Discount</Typography>
                <Grid gap={0.5} style={{ display: "flex" }}>
                  <Typography style={promoStyle.promoTitle}>Ksh.</Typography>
                  <Typography style={promoStyle.promoSubTitle2}>{promoState.promoRecord.discount && promoState.promoRecord.maxOrderValue ? Math.round((promoState.promoRecord.discount / 100) * promoState.promoRecord.maxOrderValue) : ""}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid style={{ ...promoStyle.promoSummarySubContainer, display: "flex", justifyContent: "space-between", paddingRight: "1em" }}>
              {promoState.promoRecord.validityType === VALIDITY_TYPE.timePeriod.id ? (
                <>
                  <Grid item xs={6}>
                    <Typography style={promoStyle.promoTitle}>Valid From</Typography>
                    <Grid gap={0.5} style={{ display: "flex" }}>
                      <Typography style={promoStyle.promoSubTitle2}>{promoState?.promoRecord?.validFrom ? format(parseISO(promoState?.promoRecord?.validFrom), "do MMM yyyy") : "-"}</Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography style={promoStyle.promoTitle}>Valid Till</Typography>
                    <Typography style={promoStyle.promoSubTitle2}> {promoState?.promoRecord?.validTo ? format(parseISO(promoState?.promoRecord?.validTo), "do MMM yyyy") : "-"}</Typography>
                  </Grid>
                </>
              ) : (
                <Grid item xs={6}>
                  <Typography style={promoStyle.promoTitle}>Redemption Limit</Typography>
                  <Typography style={promoStyle.promoSubTitle2}> {promoState?.promoRecord?.redemptionLimit} orders</Typography>
                </Grid>
              )}
            </Grid>

            <Divider sx={{ my: 1 }} />

            <Grid style={{ ...promoStyle.promoSummarySubContainer, display: "flex", justifyContent: "space-between", paddingRight: "1em" }}>
              <Grid item xs={6}>
                <Typography style={promoStyle.promoTitle}>Promo Type</Typography>
                <Typography style={promoStyle.promoSubTitle}>{Object.keys(promoState.promoConfig).length > 0 && Object.entries(promoState?.promoConfig?.visibilityType)?.find(([key, value]) => value === promoState?.promoRecord?.visibilityType)[0]}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography style={promoStyle.promoTitle}>Status</Typography>
                <Typography style={promoStyle.promoSubTitle2}> {promoState?.promoRecord?.status === PROMO_STATUS.active ? "Active" : "InActive"}</Typography>
              </Grid>
            </Grid>

            <Divider sx={{ my: 1 }} />

            <Grid style={{ ...promoStyle.promoSummarySubContainer, display: "flex", justifyContent: "space-between", paddingRight: "1em" }}>
              <Grid item xs={12} sx={{ py: 2, mb: 2, gap: 1 }}>
                <Typography style={promoStyle.promoTitle}>Additional Preferences</Typography>
                {promoState?.promoRecord?.rulesJson?.firstDelivery ? <Typography style={promoStyle.promoSubTitle2}>Restrict to the first transaction</Typography> : null}
                {promoState?.promoRecord?.rulesJson?.deliveryFreeWaiveOff ? <Typography style={promoStyle.promoSubTitle2}>Waive off all delivery fees</Typography> : null}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
