const { PROMO_OFFER_TYPE, VALIDITY_TYPE, PROMO_CHANNEL } = require("src/constants/constants");

export const promoCreateOrUpdateValidator = ({ promoObj, addPromoCode }) => {
  try {
    const reqBody = { ...promoObj };
    if (!promoObj.name) return { error: "Promo name is required" };
    if (!promoObj.promoCode) return { error: "Promo code is required" };
    if (!promoObj.visibilityType) return { error: "Promo visibility type is required" };
    if (!addPromoCode && !promoObj.promoCodeId) return { error: "Promo code id required" };

    if (promoObj.validityType === VALIDITY_TYPE.redemptionLimit.id) {
      if (!promoObj.redemptionLimit) return { error: "Reddemption limit is required" };
    } else {
      if (!promoObj.validFrom) return { error: "Valid From is required" };
      if (promoObj.validFrom && promoObj.validTo && new Date(promoObj.validTo) <= new Date(promoObj.validFrom)) return { error: "Valid To is less than Valid From" };
    }

    // Handle promo discount
    if (promoObj.offerType === PROMO_OFFER_TYPE.discount.id) {
      if (!promoObj.discount) return { error: "Promo discount is required" };
      if (!promoObj.minOrderValue) return { error: "Promo minimum order value is required" };
      if (promoObj.minOrderValue && promoObj.maxOrderValue && promoObj.minOrderValue >= promoObj.maxOrderValue) return { error: "Maximum order value should be greater than minimum order value" };
      if (typeof promoObj.minOrderValue === "string") return { error: "Please enter valid minimum order value." };
      if (promoObj.maxOrderValue && typeof promoObj.maxOrderValue === "string") return { error: "Please enter valid maximum order value." };

      // Product specific requirement
      if (promoObj.promoChannel === PROMO_CHANNEL.product.id && !promoObj.productVariantId) return { error: "Please select a product" };
      if (promoObj.promoChannel === PROMO_CHANNEL.brand.id && !promoObj.brandId) return { error: "Please select a brand" };
    }

    delete reqBody.promoProduct;
    delete reqBody.promoInfo;
    delete reqBody.validFromDisplay;
    delete reqBody.validToDisplay;
    delete reqBody.brandProduct;
    return reqBody;
  } catch (error) {
    return { error: error?.message };
  }
};
